<template>
  <b-card>
    <programs-table
      :options="options"
      @delete="deleteProgram"
      @edit="editProgram"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import ProgramsTable from '../../components/table/ProgramsTable.vue'
import presenterStoreModule from '../programsStoreModule'

export default {
  name: 'PresentersList',
  components: {
    ProgramsTable,
    BCard,
  },
  data() {
    return {
      storeName: 'programs',
      programs: null,
    }
  },
  computed: {
    options() {
      return {
        items: this.programs,
        columns: [
          {
            key: 'imageUrl', label: 'Image', sortable: false,
          },
          { key: 'name', label: 'Name', sortable: true },
          { key: 'programmerName', label: 'Presenter', sortable: true },
          { key: 'actions' },
        ],
      }
    },
  },
  async mounted() {
    await this.registerStore()
    await this.fetchPrograms()
  },
  destroyed() {
    if (this.$store.hasModule(this.storeName)) this.$store.unregisterModule(this.storeName)
  },
  methods: {
    async fetchPrograms() {
      let data = await this.$store.dispatch(`${this.storeName}/fetchPrograms`, { limit: '10', page: 1, q: '' })
      data = data.map(program => ({
        ...program,
        programmerName: program.Programmer.name,
        actions: [
          {
            label: 'Edit',
            on: 'edit',
          },
          {
            label: 'Delete',
            on: 'delete',
          },
        ],
      }))
      this.programs = data
    },
    async deleteProgram($event) {
      try {
        await this.$store.dispatch(`${this.storeName}/deleteProgram`, $event)
        this.$bvToast.toast('Program deleted.', {
          title: 'Success',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'success',
          solid: true,
        })
        this.fetchPrograms()
      } catch (error) {
        this.$bvToast.toast('Getting an error while deleting presenter.', {
          title: 'Error',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'error',
          solid: true,
        })
      }
    },
    editProgram(id) {
      this.$router.push({ name: 'EditProgram', query: { id } })
    },
    registerStore() {
      if (!this.$store.hasModule(this.storeName)) this.$store.registerModule(this.storeName, presenterStoreModule)
    },
  },
}
</script>

<style>

</style>
